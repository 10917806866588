import { SectionWerdegangType } from "../../types/Types";
import SectionHeading from "../common/SectionHeading";
import TimelineEntry from "./TimelineEvent";




function SectionWerdegang(props: SectionWerdegangType) {
    const resumeLength = props?.resume?.werdegang?.length ?? 0 - 1

  return (
    <div className="max-w-page mx-auto px-8 md:px-0 overflow-x-hidden" id="werdegang">
      <SectionHeading
        vortitel={props?.resume?.abschnitt_ueberschrift?.vortitel}
        ueberschrift={props?.resume?.abschnitt_ueberschrift?.ueberschrift}
        centered={true}
      />
      <div className="container mx-auto w-full h-full pt-16 pb-24 relative">
        <div className="z-20 absolute left-2 md:left-1/2 -translate-x-1/2 bg-secondary shadow-xl w-5 h-5 rounded-full" />
        <div className="relative wrap overflow-hidden h-full">
          <div className="left-2 md:left-1/2 -translate-x-1/2 border-2-2 absolute border-opacity-50 border-secondary h-full border" />
          <div className="pt-16 pb-8">
            {props?.resume?.werdegang?.map((item, index) => (
              <TimelineEntry
                key={item.id}
                item={item}
                isRight={index % 2 !== 0}
                isLastItem={index === resumeLength}
              />
            ))}
          </div>
        </div>
        <div className="z-20 absolute left-2 md:left-1/2 -translate-x-1/2 bg-secondary shadow-xl w-5 h-5 rounded-full" />
      </div>
    </div>
  )
}

export default SectionWerdegang