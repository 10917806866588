import SectionHeading from "../common/SectionHeading"
import { FabButtonType, SectionKalkulatorenType } from "../../types/Types";
import { API } from "../../services/constants";
import { useState } from "react";
import IframePopup from "./IframePopup";

type CalculatorComponentType = {
    calcData: FabButtonType[];
    section_kalkulatoren: SectionKalkulatorenType;
}


function SectionKalkulatoren(props: CalculatorComponentType) {

    const [popupOpen, setPopupOpen] = useState(false);
    const [iframeLink, setIframeLink] = useState('');

    const closePopup = () => setPopupOpen(false);
    const handleButtonClick = (link: string) => {
        setIframeLink(link);
        setPopupOpen(true);
    }

    return (
        <div className="px-4">
            <div
                className="max-w-page mx-auto  pt-8 md:pt-0 pb-8 lg:pb-16"
                id="kalkulatoren"
            >
                <SectionHeading
                    vortitel={props?.section_kalkulatoren?.abschnitt_ueberschrift?.vortitel}
                    ueberschrift={props?.section_kalkulatoren?.abschnitt_ueberschrift?.ueberschrift}
                    unterueberschrift={props?.section_kalkulatoren?.abschnitt_ueberschrift?.unterueberschrift}
                />
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8 pt-16 justify-items-center">
                    {props.calcData?.map((item, index, array) => (
                        <div
                            key={index}
                            className={`col-span-2 md:w-full ${index === array.length - 1 && array.length % 2 !== 0 ? 'md:col-span-4 md:w-full md:grid grid-cols-4' : ''}`} 
                        >
                            <div className={`${index === array.length - 1 && array.length % 2 !== 0 ? 'hidden md:block col-span-1' : 'hidden'} `} />
                            <button
                                onClick={() => handleButtonClick(item?.attributes?.link || "")}
                                className="md:flex hover:bg-base-100 border border-base-100 rounded-lg shadow-sm shadow-base-100 hover:shadow-md max-w-md md:max-w-none mx-auto col-span-2 w-full md:h-full">
                                <div className="flex bg-secondary md:h-full items-center justify-center p-4 md:p-8 flex-shrink-0 rounded-t-lg md:rounded-r-none md:rounded-l-lg ">
                                    <img
                                        className="w-20 h-20 md:w-16 md:h-16"
                                        src={API.img + item?.attributes?.icon?.data?.attributes?.url}
                                        alt={API.img + item?.attributes?.icon?.data?.attributes?.name}
                                    />
                                </div>
                                <div className="flex flex-col justify-between p-4 w-full">
                                    <h5 className="pb-2 text-lg sm:text-xl lg:text-2xl font-bold tracking-tight text-gray-900">
                                        {item?.attributes?.name}
                                    </h5>
                                    <p className="block pb-3 font-normal text-sm text-gray-700">
                                        {item?.attributes?.beschreibung}
                                    </p>
                                </div>
                            </button>
                        </div>
                    ))}
                </div>
                {popupOpen && (
                    <IframePopup
                        onClose={closePopup}
                        iframe_link={iframeLink}
                    />
                )}
            </div>
        </div>
    );
}

export default SectionKalkulatoren