import SectionHeading from '../common/SectionHeading'
import ButtonPrimary from '../common/ButtonPrimary'
import { AboutType } from '../../types/Types'
import { API } from '../../services/constants'
import { HashLink } from 'react-router-hash-link'


function SectionAbout(props: AboutType) {
    return (
        <div className='px-4'>
            <div className='max-w-page mx-auto flex flex-wrap md:flex-nowrap gap-4 md:gap-10 items-center pt-16 '>
                <img
                    src={API.img + props?.ueber?.portrait?.data?.attributes?.url}
                    alt={props?.ueber?.portrait?.data?.attributes?.name}
                    className='w-96 h-portrait-sm sm:h-portrait object-cover mx-auto'
                />
                <div className='w-full '>
                    <SectionHeading
                        vortitel={props?.ueber?.abschnitt_ueberschrift?.vortitel}
                        ueberschrift={props?.ueber?.abschnitt_ueberschrift?.ueberschrift}
                        unterueberschrift={props?.ueber?.abschnitt_ueberschrift?.unterueberschrift}
                    />
                    <div className="grid grid-cols-2 gap-2 pt-6 pb-4">
                        <div className="w-full">
                            <span className='font-bold'>
                                Name:&nbsp;
                                <br className='sm:hidden'/>
                            </span>
                            {props?.ueber?.kurzinfos?.name}
                        </div>
                        <div className="w-full">
                            <span className='font-bold'>
                                Wohnort:&nbsp;
                                <br className='sm:hidden'/>
                            </span>
                            {props?.ueber?.kurzinfos?.wohnort}
                        </div>
                        <div className="w-full">
                            <span className='font-bold'>
                                E-Mail:&nbsp;
                                <br className='sm:hidden'/>
                            </span>
                            <HashLink
                                to="#"
                                onClick={(e: { preventDefault: () => void; }) => {
                                    const mail: string = "mailto:" + props?.ueber?.kurzinfos?.mail
                                    window.location.href = mail;
                                }}
                                className="font-medium hover:underline">
                                {props?.ueber?.kurzinfos?.mail}
                            </HashLink>
                        </div>
                        <div className="w-full">
                            <span className='font-bold'>
                                Tel:&nbsp;
                                <br className='sm:hidden'/>
                            </span>
                            <HashLink
                                to="#"
                                onClick={(e: { preventDefault: () => void; }) => {
                                    const phone: string = "tel:" + props?.ueber?.kurzinfos?.tel
                                    window.location.href = phone;
                                }}
                                className="font-medium hover:underline">
                                {props?.ueber?.kurzinfos?.tel}
                            </HashLink>
                        </div>
                    </div>
                    <ButtonPrimary
                        label='Kontakt'
                        to='#kontakt'
                        full={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default SectionAbout