import { SchritteType } from "../../types/Types"
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Schritte(props: SchritteType) {

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });

    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, x: 0 });
        }
    }, [controls, inView]);
    return (
        <>
            <div className="w-12 h-12 rounded-full bg-base-300 flex items-center justify-center text-neutral font-bold text-xl flex-shrink-0">
                {props?.index + 1}.
            </div>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, x: 100 }}
                animate={controls}
                transition={{ duration: 1, delay: .5 }}
                className="flex flex-col py-3 ml-4">
                <span className="font-bold">
                    {props?.item?.schrittbezeichnung}
                </span>
                <span className="text-sm">
                    {props?.item?.schrittbeschreibung}
                </span>
            </motion.div>
        </>
    )
}

export default Schritte