import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { motion } from "framer-motion";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Loading from './components/common/Loading';
import BaseLayout from './components/layout/BaseLayout';
import Homepage from './pages/Homepage/Homepage';
import Impressum from './pages/Impressum/Impressum';
import Datenschutz from './pages/Datenschutz/Datenschutz';
import ErrorPage from './pages/404/ErrorPage';
import FloatingActionButton from './components/common/FloatingActionButton';
import { useQuery } from '@tanstack/react-query';
import { getCalcData, getFooterData, getLogoData, getSeoData } from './hooks/cmsApiCalls';
import { datenschutzSEO, impressumSEO } from './fixed_content/seo';
import IframeWrapper from './components/common/IframeWrapper';

const GA_TRACKING_ID = "X-2FJS7NAF7A" // hier ANPASSEN

function App() {

  const [loading, setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [presence, setPresence] = useState(false)
  const [hasError, setHasError] = useState(false);


  const {
    error: calcError,
    data: calcData,
    isLoading: calcLoading,
  } = useQuery({
    queryKey: ['calcData'],
    queryFn: getCalcData,
  })

  const {
    data: logoData,
  } = useQuery({
    queryKey: ['logoData'],
    queryFn: getLogoData,
  })

  const {
    error: footerError,
    data: footerData,
    isLoading: footerLoading,
  } = useQuery({
    queryKey: ['footerData'],
    queryFn: getFooterData,
  })

  const {
    error: seoError,
    data: seoData,
    isLoading: seoLoading,
  } = useQuery({
    queryKey: ['seoData'],
    queryFn: getSeoData,
  })

  useEffect(() => {
    // Setzt das Loading-Flag abhängig davon, ob irgendein Datenobjekt noch lädt
    setLoading(calcLoading || seoLoading || footerLoading);
  }, [calcLoading, seoLoading, footerLoading]);

  useEffect(() => {
    setHasError(!!(calcError || seoError || footerError));
  }, [calcError, seoError, footerError]);

  useEffect(() => {
    // Stelle sicher, dass das Laden beendet wird, wenn alle Anfragen abgeschlossen sind oder ein Fehler auftritt
    if (!calcLoading && !seoLoading && (!footerLoading || hasError)) {
      setLoading(false);
    }
  }, [calcLoading, seoLoading, footerLoading, hasError]);

  useEffect(() => {
    if (localStorage.getItem('xy_website_cookie_storage') === null) {
      console.log('call')
      setPresence(true)
      setVisible(true)
    } else if (localStorage.getItem('xy_website_cookie_storage')) {
      enableAnalytics();
    }
  }, [])

  const handleAcceptAll = () => {
    localStorage.setItem('xy_website_cookie_storage', 'true') // das anpassen!
    setVisible(!visible);
    setTimeout(() => {
      setPresence(!presence)
    }, 550)
    enableAnalytics();
  }

  const handleAcceptNecessary = () => {
    setVisible(!visible)
    setTimeout(() => {
      setPresence(!presence)
    }, 550)
  }

  // enables Google Analytics
  // hier ANPASSEN
  const enableAnalytics = () => {
    ReactGA.initialize(GA_TRACKING_ID);
  };

const handleLoading = () => {
    setLoading(true);
}

  return (
    <main className="relative overflow-y-scroll h-screen bg-neutral">
      {loading && <Loading />}
      {hasError && <ErrorPage />}
      {(presence && !loading) &&
        <motion.div
          initial={{
            y: 0,
            opacity: 1
          }}
          animate={{
            y: !visible ? '10%' : 0,
            opacity: !visible ? 0 : 1
          }}
          transition={{
            ease: 'linear',
            duration: 0.3
          }}
          className={`flex fixed bottom-16 md:right-16 w-full h-fit shadow-md border border-base-100 md:w-[36rem] md:min-h-[20rem] bg-neutral rounded-xl flex-col justify-center p-8 z-50`}>
          <h4 className='text-lg sm:text-xl md:text-2xl font-bold text-base-200 underline pb-2'>Diese Website nutzt Cookies & Google Analytics!</h4>
          <p className="text-base-200 text-xs">Wir verwenden Cookies und andere Technologien auf unserer Website. Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer Datenschutzerklärung. Sie können Ihre Auswahl jederzeit unter Einstellungen widerrufen oder anpassen.</p>
          <div className='flex flex-col-reverse justify-center items-center sm:flex-row w-full sm:justify-between mt-6'>
            <button onClick={handleAcceptNecessary} className='text-base-200 hover:text-base-300 underline hover:decoration-primary'>
              Nur notwendige zulassen
            </button>
            <button onClick={handleAcceptAll} className='border py-2 rounded-xl hover:bg-primary hover:text-neutral border-primary text-primary w-48 mb-4 sm:mb-0 duration-300'>
              Bestätigen
            </button>
          </div>
        </motion.div>}
      {!loading &&
        <div className={'flex flex-col w-full min-h-screen pt-16'}>
          <Header logo={logoData?.logo} />
          <Routes>
            <Route
              path="/"
              element={
                <BaseLayout
                  seo={seoData?.seo}
                  logo={logoData?.tab_icon}
                  children={<Homepage />}
                />
              }
            />
            <Route
              path="/iframe/:encodedUrl"
              element={
                <BaseLayout
                  seo={seoData?.seo}
                  logo={logoData?.tab_icon}
                  children={<IframeWrapper />}
                />
              }
            />
            <Route
              path="/impressum"
              element={
                <BaseLayout
                  title='Impressum'
                  seo={impressumSEO}
                  children={<Impressum />}
                />}
            />
            <Route
              path="/datenschutz"
              element={
                <BaseLayout
                  title='Datenschutz'
                  seo={datenschutzSEO}
                  children={<Datenschutz />}
                />
              }
            />
            <Route
              path="/404"
              element={
                <BaseLayout
                  title='Page Not Found'
                  children={<ErrorPage />}
                />
              }
            />
            <Route
              path="*"
              element={
                <Navigate
                  to="/404"
                />
              }
            />
          </Routes>
          <FloatingActionButton calcData={calcData} />
          <Footer
            mail={footerData?.mail}
            telefon_nummer={footerData?.telefon_nummer}
            logo_weiss={logoData?.logo_weiss}
          />
        </div>
      }
    </main>
  );
}

export default App;
