import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdOutlineCalculate } from "react-icons/md";
import { FabButtonType } from "../../types/Types";
import { API } from "../../services/constants";
import IframePopup from "../general/IframePopup";

type FabButtonComponentType = {
    calcData: FabButtonType[];
}


const FabButton = (props: FabButtonComponentType) => {
    const container = {
        hidden: {
            translateY: 50,
            opacity: 0,
        },
        show: {
            translateY: 0,
            opacity: 1,
            transition: {
                staggerChildren: -0.01,
            },
        },
    };

    const itemA = {
        hidden: { scale: 0 },
        show: { scale: 1 },
    };

    const fabRef = useRef<HTMLDivElement>(null);
    const [isFabEnabled, setIsFabEnabled] = useState(false);

    const toggleFAB = useCallback(() => {
        setIsFabEnabled((prevState) => !prevState);
    }, []);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (fabRef.current && !fabRef.current.contains(event.target as Node)) {
            setIsFabEnabled(false);
        }
    }, []);

    const [popupOpen, setPopupOpen] = useState(false);
    const [iframeLink, setIframeLink] = useState('');

    const closePopup = () => setPopupOpen(false);
    const handleButtonClick = (link: string) => {
        setIframeLink(link);
        setPopupOpen(true);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <>
            <div
                ref={fabRef}
                className="bg-accent h-16 w-16 rounded-full p-0.5 rounded-br-md fixed bottom-5 right-3 md:right-10 flex items-center justify-center shadow-accent shadow-sm hover:shadow-md hover:shadow-accent cursor-pointer transition-all ease-in z-30">
                <div
                    onClick={toggleFAB}
                    className={`select-none rounded-full w-full h-full flex items-center justify-center transition-transform ease-in ${isFabEnabled ? "rotate-[315deg]" : ""
                        }`}
                >
                    {/* FAB button icon */}
                    <MdOutlineCalculate className="text-neutral" size={30} />
                </div>

                {/* FAB button list */}
                <AnimatePresence>
                    {isFabEnabled && (
                        <motion.ul
                            variants={container}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="absolute bottom-full mb-2 right-0 flex justify-between flex-col items-end gap-2"
                        >
                            {props?.calcData?.map((item, index) => (
                                <motion.li
                                    key={index}
                                    variants={itemA}
                                    onClick={toggleFAB}
                                >
                                    <button
                                        onClick={() => handleButtonClick(item?.attributes?.link || "")}
                                        className="flex items-center relative">
                                        <div className="absolute right-full mr-4 whitespace-nowrap bg-gray-200 p-2 rounded-lg">
                                            <p>
                                                {item?.attributes?.name}
                                            </p>
                                        </div>
                                        <div className="h-14 w-14 flex items-center justify-center rounded-full bg-secondary mr-1 ">
                                            <img
                                                src={API.img + item?.attributes?.icon?.data?.attributes?.url}
                                                alt={API.img + item?.attributes?.icon?.data?.attributes?.name}
                                            />
                                        </div>
                                    </button>
                                </motion.li>
                            ))}


                        </motion.ul>
                    )}
                </AnimatePresence>
            </div>
            {popupOpen && (
                <IframePopup
                    onClose={closePopup}
                    iframe_link={iframeLink}
                />
            )}
        </>
    );
};

export default FabButton;