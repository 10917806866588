import '../../style/markdown.css'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

type RichTextType = {
  rText?: string,
}

function RichText({ rText }: RichTextType) {
  return (
    <div className='pl-6'>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        children={rText}
        className={'markdown'}
      />
    </div>
  )
}

export default RichText