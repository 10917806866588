import { API } from '../../services/constants'
import { HashLink } from 'react-router-hash-link'
import { NavType } from '../../types/Types'
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useEffect, useState, useRef } from 'react';


export default function Header(props: NavType) {

    const navLinks = [
        { to: '/#leistungen', label: 'Leistungen' },
        { to: '/#werdegang', label: 'Werdegang' },
        { to: '/#arbeitsweise', label: 'Arbeitsweise' },
        { to: '/#kalkulatoren', label: 'Kalkulatoren' },
        { to: '/#kontakt', label: 'Kontakt' },
    ];

    const mobileNavLinks = [
        { to: '/#leistungen', label: 'Leistungen' },
        { to: '/#werdegang', label: 'Werdegang' },
        { to: '/#arbeitsweise', label: 'Arbeitsweise' },
        { to: '/#kalkulatoren', label: 'Kalkulatoren' },
        { to: '/#kontakt', label: 'Kontakt' },
        // { to: '/impressum', label: 'Impressum' },
        // { to: '/datenschutz', label: 'Datenschutz' },
    ];

    const [nav, setNav] = useState(false);
    const navRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (nav && navRef.current && !navRef.current.contains(event.target as Node)) {
                setNav(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [nav]);

    return (
        <div className=" bg-neutral shadow-lg px-4 xl:px-0 fixed top-0 left-0 right-0 z-50 ">
            <div className='navbar px-0 max-w-page mx-auto '>
                <div className="flex-1">
                    <HashLink
                        to={"/"}
                        className="cursor-pointer">
                        <img
                            src={API.img + props?.logo?.data?.attributes?.url}
                            className='w-20 py-2'
                            alt="Ralf Heidig Logo"
                        />
                    </HashLink>
                </div>
                <div className="flex-none">
                    <ul className="menu hidden  sm:menu-horizontal px-1 gap-4 md:gap-8  lg:gap-16">
                        {navLinks.map((link, index) => (
                            <div key={index}>
                                <HashLink
                                    to={link.to}
                                    smooth
                                    className={` hover:underline font-k2d decoration-primary text-lg font-semibold underline-offset-4 decoration-2 `}>
                                    {link.label}
                                </HashLink>
                            </div>
                        ))}
                    </ul>
                </div>
                {/* Hamburger Icon */}
                <div className="sm:hidden fixed right-5 ml-auto z-[105]">
                    <button onClick={() => setNav(!nav)}>
                        {nav ? <AiOutlineClose size={35} className='text-neutral' /> : <AiOutlineMenu size={35} />}
                    </button>
                </div>
            </div>
            <div>
                {/* Mobile Navigation */}
                <ul
                    ref={navRef}
                    className={`z-[100] flex flex-col fixed top-0 right-0 pt-20 w-[65%] h-full bg-primary transform shadow-2xl border-l-1 border-secondary ${nav ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out sm:hidden items-start pb-4`}>
                    {mobileNavLinks.map((link, index) => (
                        <li
                            key={index}
                            className='py-4 px-6'>
                            <HashLink
                                to={link.to}
                                smooth
                                onClick={() => setNav(!nav)}
                                className="block text-neutral font-semibold hover:bg-opacity-10 hover:bg-secondary">
                                {link.label}
                            </HashLink>
                        </li>
                    ))}
                    <div className='grow'></div>
                    <li className='py-4 px-6 justify-self-end'>
                        <HashLink
                            to={'/impressum#impressum'}
                            onClick={() => setNav(!nav)}
                            className="block text-neutral  hover:bg-opacity-10 hover:bg-secondary ">
                            Impressum
                        </HashLink>
                    </li>
                    <li className='py-4 px-6 justify-self-end'>
                        <HashLink
                            to={'/datenschutz#datenschutz'}
                            onClick={() => setNav(!nav)}
                            className="block text-neutral hover:bg-opacity-10 hover:bg-secondary ">
                            Datenschutz
                        </HashLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}