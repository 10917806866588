import ButtonPrimary from "../../components/common/ButtonPrimary"

export default function ErrorPage() {
  return (
    <div className="w-full h-screen  flex justify-center items-center px-4">
      <div className="flex flex-col text-center -translate-y-16 max-w-xl items-center">
        <div className="text-9xl text-primary">
          404
        </div>
        <div className="text-2xl mb-4">
          PAGE NOT FOUND
        </div>
        <p className="pb-4">
          Die gesuchte Seite wurde möglicherweise entfernt, umbenannt oder ist vorübergehend nicht verfügbar. Bitte überprüfen Sie die URL oder kehren Sie zur Startseite zurück.
        </p>
        <ButtonPrimary label="Zurück zur Startseite" to="/" />
      </div>
    </div>
  )
}
