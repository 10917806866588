import axios from 'axios'
import { API } from '../services/constants'

// ============= GLOBAL ============= //
export const getCalcData = () =>
    axios
        .get(
            API.general +
            '/kalkulatorens?populate=*'
        )
        .then((res) => res.data.data)

export const getLogoData = () =>
    axios
        .get(
            API.general +
            '/logo?populate[logo][populate]=*&populate[logo_weiss][populate]=*&populate[tab_icon][populate]=*'
        )
        .then((res) => res.data.data.attributes)


export const getFooterData = () =>
    axios
        .get(
            API.general +
            '/footer?populate=*'
        )
        .then((res) => res.data.data.attributes)

export const getSeoData = () =>
    axios
        .get(
            API.general +
            '/seo?populate=*'
        )
        .then((res) => res.data.data.attributes)



// ============= HOMEPAGE ============= //

export const getHomeData = () =>
    axios
        .get(
            API.general +
            '/startseite?populate[resume][populate]=*&populate[arbeitsweise][populate]=*&populate[kontakt][populate]=*&populate[kalkulatoren][populate]=*&populate[ueber][populate]=*&populate[hero][populate]=*&populate[kundenanliegen][populate][anliegen][populate]=*&populate[kundenanliegen][populate][abschnitt_ueberschrift][populate]=*'
        )
        .then((res) => res.data.data.attributes)


// ============= IMPRESSUM ============= //

export const getImpressumData = () =>
    axios
        .get(
            API.general +
            '/impressum?populate=*'
        )
        .then((res) => res.data.data.attributes)


// ================== DATENSCHUTZ ================== //

export const getDatenschutzData = () =>
    axios
        .get(
            API.general +
            '/datenschutz?populate=*'
        )
        .then((res) => res.data.data.attributes)