
import { HashLink } from 'react-router-hash-link';
import { API } from '../../services/constants';
import { FooterType, LogoType } from '../../types/Types';

export default function Footer(props: FooterType & LogoType) {
  return (
    <div className="w-full bg-base-200 text-neutral px-8 py-12">
      <div className="max-w-page mx-auto">
        <div className="grid gap-8 grid-cols-1 md:grid-rows-1 md:grid-cols-3 ">
          
            <img
              src={API.img + props?.logo_weiss?.data?.attributes?.url}
              alt={props?.logo?.data?.attributes?.name}
              className="w-24 sm:w-36" />
          
          <div className="flex flex-col gap-y-2 ">
            <h4 className="text-xl font-medium pb-2 md:pb-6">
              Mehr Erfahren
            </h4>
            <HashLink to={"/impressum"}>
              <p className="font-light hover:underline">
                Impressum
              </p>
            </HashLink>
            <HashLink to={"/datenschutz"}>
              <p className="font-light hover:underline">
                Datenschutz
              </p>
            </HashLink>
          </div>
          <div className="flex flex-col gap-y-2">
            <h4 className="text-xl font-medium  pb-2 md:pb-6">
              Nehmen Sie Kontakt auf
            </h4>
            <div className="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 md:justify-between">
              <p className="font-light w-20">
                Telefon:
              </p>
              <HashLink
                to="#"
                onClick={(e: { preventDefault: () => void; }) => {
                  const phone: string = "tel:" + props?.telefon_nummer
                  window.location.href = phone;
                }}
                className="font-medium hover:underline">
                {props?.telefon_nummer}
              </HashLink>
            </div>
            <div className="flex flex-col sm:flex-row gap-y-2 sm:gap-y-0 md:justify-between ">
              <p className="font-light w-20">
                E-Mail:
              </p>
              <HashLink
                to="#"
                onClick={(e: { preventDefault: () => void; }) => {
                  const mail: string = "mailto:" + props?.mail
                  window.location.href = mail;
                }}
                className="font-medium hover:underline">
                {props?.mail}
              </HashLink>
            </div>
          </div>
        </div>
        <hr className="my-12 text-underline border-1" />
        <h4 className=" w-fit mx-auto font-thin text-base ">
          © 2024 Ralf Heidig Beratung GmbH
        </h4>
      </div>

    </div>
  )
}
