import React, { ReactNode, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


type TimelineEntryProps = {
    item: any;
    isRight: boolean;
    isLastItem: boolean;
}

const TimelineEntry: React.FC<TimelineEntryProps> = ({ item, isRight, isLastItem }) => {
    const variants = {
        hidden: {
            x: isRight ? 100 : -100,
            opacity: 0
        },
        visible: {
            x: 0,
            opacity: 1,
            transition: { 
                type: 'ease', 
                duration: 0.5 }
        }
    };

    const [ref, inView] = useInView({
        threshold: 0.3,
        triggerOnce: true
    });
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={variants}
            className={`flex ${isRight ? 'justify-between' : 'justify-between md:flex-row-reverse'} items-center w-full ${isLastItem ? '' : 'pb-8'} `}
        >
            <div className="hidden md:block order-1 w-5/12" />
            <div className="z-20 flex items-center order-1 bg-secondary shadow-xl w-4 h-4 rounded-full" />
            <div className={`order-1 ${isRight ? 'text-left' : 'md:text-right'} w-10/12 sm:w-11/12 md:w-5/12 px-6 py-4`}>
                <h4 className="mb-1 md:mb-2 font-bold text-base-200 sm:text-xl">
                    {item.taetigkeit}
                </h4>
                <p className="text-sm font-medium leading-snug tracking-wide text-base-200 text-opacity-100">
                    {item.ort_zeit}
                </p>
            </div>
        </motion.div>
    );
};

export default TimelineEntry;