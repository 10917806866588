export const impressumSEO = {
    metaTitle: "Impressum | Ralf Heidig Unternehmensberatung",
    metaDescription: "Offizielles Impressum von Ralf Heidig Unternehmensberatung. Hier finden Sie alle rechtlichen Informationen und Kontaktdaten.",
    canonicalUrl: "https://www.rh-beratung.de/impressum",
    metaRobots: "index, follow"
  }

  export const datenschutzSEO = {
    metaTitle: "Datenschutzrichtlinie | Ralf Heidig Unternehmensberatung",
    metaDescription: "Lesen Sie unsere Datenschutzrichtlinie, um zu verstehen, wie wir Ihre persönlichen Daten schützen und verwenden.",
    canonicalUrl: "https://www.rh-beratung.de/datenschutz",
    metaRobots: "noindex, follow"
  }