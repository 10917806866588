
import { LeistungenType } from "../../types/Types";
import Leistung from "./Leistung";


function SectionLeistungen(props: LeistungenType) {



  return (
    <div
      className="bg-neutral overflow-x-hidden"
      id="leistungen"
    >
      <div className='py-16 overflow-x-hidden'>
        <div className="bg-base-100 py-8 px-4">
          <div className='max-w-page mx-auto'>
            <p className="text-accent font-medium">
              -{props?.leistungen?.abschnitt_ueberschrift?.vortitel}-
            </p>
            <h2 className='text-4xl font-bold pb-4'>
              {props?.leistungen?.abschnitt_ueberschrift?.ueberschrift}
            </h2>
            <p>
              {props?.leistungen?.abschnitt_ueberschrift?.unterueberschrift}
            </p>
          </div>
        </div>

        {props?.leistungen?.anliegen?.map((leistung, index) => {


          return (
            <div key={leistung.id}>
              <Leistung 
              index={index}
              leistung={leistung} />
            </div>
          );
        })}



      </div>

    </div>
  );
}

export default SectionLeistungen;




