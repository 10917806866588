import { HashLink } from "react-router-hash-link";
import SectionHeading from "../common/SectionHeading";
import { SectionContactType } from "../../types/Types";
import ContactForm from "./ContactForm";

function SectionContact(props: SectionContactType) {
    return (
        <div className="px-4">
            <div
                className="max-w-page mx-auto flex flex-col md:flex-row  md:gap-x-8 gap-y-8 md:gap-y-0 pb-16 pt-8 lg:py-16 "
                id="kontakt"
            >
                <div className=" flex w-full md:w-5/12 flex-col  gap-y-3 md:gap-y-6">
                    <SectionHeading
                        vortitel={props?.kontakt?.abschnitt_ueberschrift?.vortitel}
                        ueberschrift={props?.kontakt?.abschnitt_ueberschrift?.ueberschrift}
                        unterueberschrift={props?.kontakt?.abschnitt_ueberschrift?.unterueberschrift}
                    />
                    <div className="flex flex-col w-full gap-y-2">
                        <h4 className="text-2xl font-semibold">
                            E-Mail
                        </h4>
                        <HashLink
                            to="#"
                            className="hover:underline ease-in duration-200 w-fit"
                            onClick={(e: { preventDefault: () => void; }) => {
                                const mail: string = "mailto:" + props?.kontakt?.kontaktinformationen?.email
                                window.location.href = mail;
                            }}>
                            <p>
                                {props?.kontakt?.kontaktinformationen?.email}
                            </p>
                        </HashLink>
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                        <h4 className="text-2xl font-semibold">
                            Telefon
                        </h4>
                        <HashLink
                            to="#"
                            className="hover:underline ease-in duration-200 w-fit"
                            onClick={(e: { preventDefault: () => void; }) => {
                                const mail: string = "tel:" + props?.kontakt?.kontaktinformationen?.telefon_nummer
                                window.location.href = mail;
                            }} >
                            <p>
                                {props?.kontakt?.kontaktinformationen?.telefon_nummer}
                            </p>
                        </HashLink>
                    </div>
                    <div className="flex flex-col w-full gap-y-2">
                        <h4 className="text-2xl font-semibold">
                            Social Media
                        </h4>
                        <HashLink
                            to={props?.kontakt?.kontaktinformationen?.instagram_link}
                            smooth
                            className="hover:underline ease-in duration-200 w-fit"
                            target="_blank">
                            <p className="">
                                Instagram
                            </p>
                        </HashLink>
                        <HashLink
                            to={props?.kontakt?.kontaktinformationen?.facebook_link}
                            smooth
                            className="hover:underline ease-in duration-200 w-fit"
                            target="_blank">
                            <p className="">
                                Facebook
                            </p>
                        </HashLink>
                    </div>
                </div>
                <div className="w-full md:w-7/12 flex flex-col">
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default SectionContact