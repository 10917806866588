import { MdOutlineCancel } from 'react-icons/md';
import { IframePopupType } from '../../types/Types';

function IframePopup({ onClose, iframe_link }: IframePopupType) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
            <div className="bg-neutral px-8 py-10 md:px-24 md:py-16 rounded-lg max-w-page w-full relative">
                <button className='absolute top-0 right-0 text-4xl text-secondary lg:active:animate-ping hover:duration-300 hover:rotate-90 mt-4 mr-4' onClick={onClose}>
                    <MdOutlineCancel className="block" />
                </button>
                <div className='mt-4 flex overflow-y-auto max-h-[80vh] '>
                    <iframe
                        src={iframe_link}
                        title={iframe_link}
                        className="w-full h-[80vh] overflow-x-hidden border-none"
                    />
                </div>
            </div>
        </div>

    )
}

export default IframePopup