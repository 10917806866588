import RichText from '../../components/common/RichText'
import { useQuery } from '@tanstack/react-query'
import { getImpressumData } from '../../hooks/cmsApiCalls'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'

export default function Impressum() {

  const {
    error: impressumError,
    data: impressumData,
    isLoading: impressumLoading,
  } = useQuery({
    queryKey: ['impressumData'],
    queryFn: getImpressumData,
  })

  return (
    <>
      {impressumError && <ErrorPage />}
      {impressumLoading && <Loading />}
      {impressumData &&
        <div
          className='max-w-page mx-auto pt-16 -mt-16 '
          id='impressum'
        >
          <RichText rText={impressumData?.Inhalt} />
        </div>
      }
    </>
  )
}
