import { MdArrowBack } from "react-icons/md";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link"

type IframeWrapperType = {
    iframe_link?: string

}


function IframeWrapper(props: IframeWrapperType) {
    const { encodedUrl } = useParams();
    const iframeSrc = decodeURIComponent(encodedUrl || '');

    console.log(iframeSrc)
    return (
        <div className="max-w-page mx-auto flex flex-col md:flex-row py-2 md:py-16 gap-4">
            <HashLink to="/#kalkulatoren" className="bg-primary hover:bg-accent duration-200 ease-in p-4 rounded-full w-fit h-fit ml-4">
                <MdArrowBack className="text-neutral" />
            </HashLink>
            <iframe src={iframeSrc} className="w-screen h-screen"></iframe>
            
        </div>
    )
}

export default IframeWrapper