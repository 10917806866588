import { ReactNode } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ImageType } from '../../types/Types'
import { API } from '../../services/constants'

export type BaseLayoutType = {
  title?: string,
  seo?: {
    metaTitle?: string,
    metaDescription?: string,
    cannonicalUrl?: string,
    keywords?: any,
    structuredData?: any,
    metaRobots?: any,
    metaSocial?: {
      socialNetwork?: string,
      title?: string,
      description?: string,
      image?: ImageType,
    }
  },
  logo?: ImageType,
  children: ReactNode
}

export default function BaseLayout({ title, seo, logo, children }: BaseLayoutType) {
  return (
    <section className='grow w-full flex flex-col items-center justify-start'>
      <HelmetProvider>
        <Helmet>
          <title>{seo?.metaTitle || title}</title>
          {seo && (
            <link
              rel='icon'
              type="image/svg+xml"
              href={
                API.img +
                logo?.data?.attributes?.url
              }
            />
          )}
          {seo && (
            <link
              rel='apple-touch-icon'
              href={
                API.img +
                logo?.data?.attributes?.url
              }
            />
          )}
          {seo?.cannonicalUrl && (
            <link rel='canonical' href={seo?.cannonicalUrl} />
          )}
          {seo?.metaDescription && (
            <meta name='description' content={seo?.metaDescription} />
          )}
          {seo?.metaDescription && (
            <meta property='og:description' content={seo?.metaDescription} />
          )}
          {seo?.keywords && (
            <meta name='keywords' content={seo?.keywords} />
          )}
          {seo?.metaRobots && (
            <meta name='robots' content={seo?.metaRobots} />
          )}
          {seo?.structuredData && (
            <script type='application/ld+json'>
              {JSON.stringify(seo?.structuredData)}
            </script>
          )}
        </Helmet>
      </HelmetProvider>
      {children}
    </section>
  )
}
