import dreieckWeiß from '../../assets/Polygon 2.svg'
import DreieckBlau from '../../assets/Polygon 1 (2).svg'
import { API } from "../../services/constants";
import RichText from "../common/RichText";
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { leistungEinzelnType } from '../../types/Types';




function Leistung(props:leistungEinzelnType) {
    const [ref, inView] = useInView({
      threshold: 0.3,
      triggerOnce: true
    });
  
    const controls = useAnimation();
  
    useEffect(() => {
      if (inView) {
        controls.start({ opacity: 1, x: 0 });
      }
    }, [controls, inView]);
  
    return (
      <>
        <div className={`${props?.index % 2 !== 0 ? ' bg-neutral' : 'bg-base-100'} w-full px-4`}>
          <div className={`grid sm:grid-cols-2 max-w-page mx-auto gap-4 lg:gap-20  pt-16 ${props?.leistung?.id === 1 ? 'pt-4' : ''} pb-8 items-center`}>
            <motion.div
              ref={ref}
              initial={{ opacity: 0, x: props?.index % 2 !== 0 ? -100 : 100 }}
              animate={controls}
              transition={{ duration: 1, delay: .5 }}
              className={`${props?.index % 2 === 0 ? 'order-2' : 'order-2 sm:order-1'} my-auto sm:max-w-sm sm:mx-auto `}
            >
              <h3 className='text-3xl font-semibold mb-4'>
                {props?.leistung?.zwischenueberschrift}
              </h3>
              <RichText rText={props?.leistung?.anstriche} />
            </motion.div>
            <motion.img
              ref={ref}
              initial={{ opacity: 0, x: props?.index % 2 !== 0 ? 100 : -100 }}
              animate={controls}
              transition={{ duration: 1, delay: .5 }}
              src={API.img + props?.leistung?.bild?.data?.attributes?.url}
              alt={props?.leistung?.bild?.data?.attributes?.name}
              className={`${props?.index % 2 === 0 ? 'order-1' : 'order-1 sm:order-2'}  max-h-80 w-full object-cover `}
            />
          </div>
        </div>
        <img
          src={DreieckBlau}
          alt="Blaues Dreieck"
          className={`${props?.index % 2 !== 0 ? 'hidden' : 'bg-neutral'} w-full`}
        />
        <img
          src={dreieckWeiß}
          alt="Weißes Dreieck"
          className={`${props?.index % 2 !== 0 ? 'bg-base-100' : 'hidden'} w-full`}
        />
      </>
    )
  }

  export default Leistung