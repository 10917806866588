import { MdArrowForwardIos } from "react-icons/md";
import { HashLink } from "react-router-hash-link";
import { ButtonType } from "../../types/Types";


function ButtonPrimary(props: ButtonType) {
    return (
        <HashLink
            to={props.to}
            smooth
            className={`group ${props.full ? 'w-full' : 'w-fit'} sm:w-fit bg-primary hover:bg-accent transition cursor-pointer py-2 px-6 flex gap-2 items-center justify-center text-neutral rounded-lg `}>
            {props.label}
            <MdArrowForwardIos />

        </HashLink>
    )
}

export default ButtonPrimary