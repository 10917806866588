

function ContactForm() {
  return (
    <form className="flex flex-col gap-y-4">
                    <div className="flex flex-col gap-y-2">
                        <label>
                            Name
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Geben Sie Ihren Namen ein"
                            className="py-2 px-4 rounded-md border border-secondary bg-base-100 focus:outline-secondary"
                            required />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <label>
                            E-Mail
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Geben Sie Ihre E-Mail ein"
                            className="py-2 px-4 rounded-md border border-secondary bg-base-100 focus:outline-secondary"
                            required />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <label>
                            Was ist Ihr Anliegen?
                        </label>
                        <input
                            type="text"
                            name="anliegen"
                            placeholder="Geben Sie Ihr Anliegen ein"
                            className="py-2 px-4 rounded-md border border-secondary bg-base-100 focus:outline-secondary"
                            required />
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <label>
                            Nachricht
                        </label>
                        <textarea
                            name="message"
                            placeholder="Geben Sie Ihre Nachricht ein"
                            className="py-2 px-4 h-36 rounded-md border border-secondary bg-base-100 focus:outline-secondary"
                            required>
                        </textarea>
                    </div>
                    <button className="w-full p-2 rounded-lg bg-primary shadow-md text-neutral hover:bg-accent ease-in-out duration-300">
                        Abschicken
                    </button>
                </form>
  )
}

export default ContactForm