import { API } from "../../services/constants";
import { SectionArbeitsweiseType } from "../../types/Types";
import SectionHeading from "../common/SectionHeading";
import "../../style/arbeitsweise.css";
import Schritte from "./Schritte";

function SectionArbeitsweise(props: SectionArbeitsweiseType) {
    return (
        <div
            className="relative bg-primary text-neutral mb-8 md:mb-16 overflow-x-hidden"
            id="arbeitsweise"
        >
            <div className="py-16 md:py-24 px-4 xl:px-0 max-w-page mx-auto flex flex-col md:flex-row gap-y-8 md:gap-y-0">
                <div className="md:flex-grow">
                    <div className="max-w-lg">
                        <SectionHeading
                            vortitel={props?.arbeitsweise?.abschnitt_ueberschrift?.vortitel}
                            ueberschrift={props?.arbeitsweise?.abschnitt_ueberschrift?.ueberschrift}
                            unterueberschrift={props?.arbeitsweise?.abschnitt_ueberschrift?.unterueberschrift}
                            dark={true}
                        />
                    </div>

                    <div className="hidden md:block relative w-full h-full">
                        <div className="md:absolute -bottom-10 -left-10">
                            {/* Background div with offset */}
                            <div className="absolute -translate-x-4 -translate-y-8 z-0 rounded-full bg-base-300 w-96 h-96" />
                            {/* Image with higher z-index to appear above the background */}
                            <img
                                className="relative z-10 md:rounded-full w-96 h-96 object-cover"
                                src={API.img + props?.arbeitsweise?.arbeitsweise_bild?.data?.attributes?.url}
                                alt={API.img + props?.arbeitsweise?.arbeitsweise_bild?.data?.attributes?.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="relative  md:w-[52%]">
                    {/* Vertikale Linie, Höhe dynamisch berechnet */}
                    <div
                        className="iframe-custom-height absolute left-5 top-0 w-2 bg-base-300 z-0">
                    </div>
                    {/* Dots und Text */}
                    <div className="flex flex-col gap-y-12">
                        {/* Wiederholung für jeden Dot mit Text */}
                        {props?.arbeitsweise?.schritte?.map((item, index) => (
                            <div
                                key={index}
                                className="flex z-10">
                                <Schritte
                                    index={index}
                                    item={item}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <img
                src={API.img + props?.arbeitsweise?.arbeitsweise_bild?.data?.attributes?.url}
                alt={API.img + props?.arbeitsweise?.arbeitsweise_bild?.data?.attributes?.name}
                className="md:hidden h-36 w-full object-cover"
            />

            <div className="hidden md:block h-32 bg-neutral w-full" />
        </div>
    )
}

export default SectionArbeitsweise