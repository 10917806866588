import { HeadingType } from '../../types/Types'



function SectionHeading(props: HeadingType) {
  return (
    <div className={` ${props?.centered ? 'md:mx-auto md:text-center' : ''}`}>
      <p className={`${props?.dark ? 'text-base-100' : 'text-accent'} text-lg`}>
        -{props?.vortitel}-
      </p>
      <h2 className={`text-4xl pb-2 font-semibold ${props?.dark ? 'text-neutral' : ''}`}>
        {props?.ueberschrift}
      </h2>
      <p className={`${props?.dark ? 'text-base-100' : ''}`}>
        {props?.unterueberschrift}
      </p>
    </div>
  )
}

export default SectionHeading