import ButtonPrimary from "../common/ButtonPrimary"
import { HeroType } from "../../types/Types"
import { API } from "../../services/constants"


function Hero(props: HeroType) {
    return (
        <div
            style={{ backgroundImage: `url(${API.img + props?.hero?.hintergrundbild?.data?.attributes?.url})` }}
            className="relative w-full h-fit sm:h-hero-sm 2xl:h-hero px-4  bg-cover bg-center overflow-hidden"
        >
            <div className="absolute inset-0 bg-gradient-to-r from-[#0f4c85a1] to-[#0f4c8531] sm:to-[#0f4c8500] bg-black bg-opacity-30" />
            <div className="relative h-full flex flex-col justify-center max-w-page mx-auto text-neutral py-24 sm:py-8">
                <h1 className="text-5xl font-bold pb-2">
                    {props?.hero?.titel}
                </h1>
                <p className="text-xl pb-2">
                    -{props?.hero?.untertitel}-
                </p>
                <p className="pb-4 md:w-2/3">
                    {props?.hero?.text}
                </p>
                <div className="flex w-full justify-start">
                <ButtonPrimary
                    label="Kontakt"
                    to="#kontakt"
                    full={false}
                />
                </div>

                


            </div>
        </div>
    )
}

export default Hero