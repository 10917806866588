
import '../../style/loading.css'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

export default function Loading() {
  return (
	  <section className='h-full w-full bg-white overflow-hidden'>
		  <Box sx={{ width: '100%',  height: '100%'}}>
			<LinearProgress />
		</Box>
    </section>
  )
}
