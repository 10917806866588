
import { useQuery } from '@tanstack/react-query'
import { getDatenschutzData } from '../../hooks/cmsApiCalls'
import Loading from '../../components/common/Loading'
import ErrorPage from '../404/ErrorPage'
import RichText from '../../components/common/RichText'

export default function Datenschutz() {

  const {
    error: datenschutzError,
    data: datenschutzData,
    isLoading: datenschutzLoading,
  } = useQuery({
    queryKey: ['datenschutzData'],
    queryFn: getDatenschutzData,
  })

  return (
    <>
      {datenschutzError && <ErrorPage />}
      {datenschutzLoading && <Loading />}
      {datenschutzData &&
        <div
          className='max-w-page mx-auto pt-16 -mt-16 '
          id='datenschutz'
        >
          <RichText rText={datenschutzData?.Inhalt} />
        </div>
      }
    </>
  )
}
