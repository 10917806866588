
import { useQuery } from '@tanstack/react-query';
import Leistungen2 from '../../components/general/SectionLeistungen';
import Hero from '../../components/general/Hero';
import SectionContact from '../../components/general/SectionContact';
import Arbeitsweise from '../../components/general/SectionArbeitsweise';
import { getCalcData, getHomeData } from '../../hooks/cmsApiCalls';
import SectionWerdegang from '../../components/general/SectionWerdegang';
import ErrorPage from '../404/ErrorPage';
import Loading from '../../components/common/Loading';
import SectionKalkulatoren from '../../components/general/SectionKalkulatoren';
import About from '../../components/general/SectionAbout';

export default function Homepage() {
  
  const {
    error: homeError,
    data: homeData,
    isLoading: homeLoading,
  } = useQuery({
    queryKey: ['homeData'],
    queryFn: getHomeData,
  })


  const {
    error: calcError,
    data: calcData,
    isLoading: calcLoading,
  } = useQuery({
    queryKey: ['calcData'],
    queryFn: getCalcData,
  })

  return (
    <>
      {(homeError || calcError) && <ErrorPage />}
      {(homeLoading || calcLoading) && <Loading/>}
      {
        homeData && calcData &&
        <section className='font-k2d w-full'>
          <Hero hero={homeData?.hero}/>
          <About ueber={homeData?.ueber}/>
          {/* <Leistungen1 /> */}
          <Leistungen2 leistungen={homeData?.kundenanliegen} />
          <SectionWerdegang resume={homeData?.resume} />
          <Arbeitsweise arbeitsweise={homeData?.arbeitsweise} />
          <SectionKalkulatoren calcData={calcData} section_kalkulatoren={homeData?.kalkulatoren}/>
          <SectionContact kontakt={homeData?.kontakt}/>

        </section>
      }
    </>
  )
}
